import Link from "../components/link"
import React, { useEffect } from "react"
import { toggleMenu } from "../lib/toggleMenu"
import NewsletterForm from "../components/newsletterForm"
const Footer = ({ footerClassName, clicked }) => {

  useEffect(() => {
    document.querySelector('.cta--trigger').addEventListener("click", toggleMenu);
    window.hbspt.forms.create({
      region: "na1",
      portalId: "9226978",
      formId: "da19a315-f025-49d3-a70f-5a512d5395c8",
      target: "#footer-form",
    })
  }, [])

  const Contact = ({className}) => {
    return (
      <div className={"social-accounts caption m-mb10 " + className}>
        <a className="link ul-link mr20 footer--small--text" target="_blank" rel="noreferrer" href="https://www.instagram.com/sieragroup/">Instagram</a>
        
        <a className="link ul-link mr20 footer--small--text" target="_blank" rel="noreferrer" href="https://www.facebook.com/sieragroup">Facebook</a>
        <a className="link ul-link mr20 footer--small--text" target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCvCNr4mtCh0Ob4nwH_7RStA">Youtube</a>
        <a className="link ul-link footer--small--text" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/siera-group/">Linkedin</a>
      </div>
    )
  }

  return (
    <div className={"footer p40 m-pb40 m-pt0 " + footerClassName}>
      <div className="max-1400 ma bt1 pt40 flex m-wrap m-pt150">
        <div className="subscribe m-100 mr20">
          <p className="max-250 h2 mt0 mb40 m-mb80 ">Subscribe to our mailing list to stay in touch with the latest</p>
          <div className='w-100' id='footer-form'/>
          <div className="spacer"></div>
          <Contact  className='m-hide'/>
        </div>
        <div className="max-450 mla w-100 m-mla">
          <div className="flex flex-wrap h-100">
            <div className="flex mba w-100 m-wrap">
              <div className="w-50 m-flex m-100">
                <p className="m0 h4 m-w-40 m-flex-grow-1">Menu</p>
                <div className="spacer"></div>
                <div className='links op-50'>
                  <p className="m0 mb5"><Link className="m0 link ul-link" to='/'>Home</Link></p>
                  <p className="m0 mb5"><Link className="m0 link ul-link" to='/story'>About Us</Link></p>
                  <p className="m0 mb5"><Link className="m0 link ul-link" to='/developments'>Projects</Link></p>
                  <p className="m0 mb5"><Link className="m0 link ul-link" to='/news'>News</Link></p>
                  <p className="m0"><span className="cta--trigger m0 link ul-link">Contact</span></p>
                </div>
              </div>
              <div className="w-50 m-flex m-100 m-mt20 m-mb80">
                <p className="m0 h4 m-w-40 m-flex-grow-1">Location</p>
                <div className="spacer"></div>
                <div className="location op-50">
                  Level 6/ 145 Eagle Street, <br />
                  Brisbane City, <br />
                  QLD 4000
                </div>
              </div>
            </div>
            <Contact  className='m-show'/>
            <div className="m-spacer"></div>
            <div className="mta w-100 flex  m-wrap ">
              <Link to='/privacy-policy' className="w-50 m-100 m-mb10 link ">
                <span className="caption footer--small--text"> Privacy Policy</span >
               
              </Link>
              <div className="w-50 m-100 link ">
                <span className="caption footer--small--text "> Crafted by </span><a className="link ul-link uppercase footer--small--text" target="_blank" rel="noreferrer" href="https://groundcrew.com.au">Groundcrew</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
